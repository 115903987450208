export const MODALS = {
  ADD_CREATE: "add-create",
  CREATE_TRIP: "create-trip",
  INVITE_TO_TRIP: "invite-to-trip",
  EDIT_TRIP: "edit-trip",
  CREATE_ORGANISATION: "create-dive-company",
  DIVE_SITE: "dive-site",
  DIVE_SITE_EDIT: "dive-site-edit",
  DIVE_LOG: "dive-log",
  DIVE_LOG_EDIT: "dive-log-edit",
  ORGANISATION_EDIT: "dive-company-edit",
  PREFERENCES: "preferences",
  UPLOAD_LOG_BOOK: "upload-log-book",
  UPLOAD_DIVE_PHOTOS: "upload-dive-photos",
  TRIP_REVIEW: "trip-review",
  EDIT_TRIP_REVIEW: "edit-trip-review",
  EDIT_PROFILE: "edit-profile",
} as const;

export type ModalTypes = (typeof MODALS)[keyof typeof MODALS];
